





































































































































import Vue from "vue";
import { Component, Prop, Watch, Model } from "vue-property-decorator";
import { State, Action, Getter, Mutation, namespace } from "vuex-class";

import { api } from "@/store/modules/audit";
import {
  AuditItem,
  AuditRemoteExternalMeasuresSettings,
  AuditRemoteSettings,
} from "@auditcloud/shared/lib/schemas";
import { ActivateExternalMeasuresParams } from "@/store/modules/audit/types";
const auditModule = namespace(api.namespace);

import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import firebase from "firebase/compat/app";
import { DAY_IN_MS } from "@auditcloud/shared/lib/constants";
import { AuditPermissions } from "@auditcloud/shared/lib/utils/aclHelpers";
import { Timestamp } from "firebase/firestore";

@Component({
  components: {},
})
export default class AExternalMeasureProcessingSidebar extends Vue {
  reviser: null | IUserRef = null;
  dueDate: null | string = null;
  loading: boolean = false;
  assessQuestions: boolean = false;
  message: string = "";
  additionalQuestionListId: string | null = null;

  remoteActivationLoading: boolean = false;
  linkCopyHint: string | null = null;

  @auditModule.Action(api.actions.activateExternalMeasures)
  activateExternalMeasures!: (
    payload: ActivateExternalMeasuresParams
  ) => Promise<any>;

  @auditModule.Getter(api.getters.getAuditRemoteSettings)
  auditRemoteSettings!: AuditRemoteSettings | null;

  @auditModule.Getter(api.getters.getAuditPermissions)
  auditPermissions!: AuditPermissions;

  get externalMeasuresSettings(): AuditRemoteExternalMeasuresSettings | null {
    return this.auditRemoteSettings?.externalMeasures || null;
  }

  get remoteLink(): string | null {
    if (this.externalMeasuresSettings) {
      if (
        !this.externalMeasuresSettings.completed &&
        firebase.firestore.Timestamp.now().toMillis() <=
          this.externalMeasuresSettings.until.toMillis()
      ) {
        return this.externalMeasuresSettings.link;
      }
    }
    return null;
  }

  get linkValidUntil(): string {
    return this.externalMeasuresSettings?.until.toDate().toLocaleString() ?? "";
  }

  get remoteLinkHint(): string | null {
    if (this.linkValidUntil !== "") {
      return (
        this.$t("components.widgets.sidebar.external_measures.valid_until", {
          date: this.linkValidUntil,
        }) + (this.linkCopyHint ? ` (${this.linkCopyHint})` : "")
      );
    }
    return null;
  }

  get hasPermissions() {
    return this.auditPermissions.changeExternalMeasureProcessingStatus;
  }

  async next() {
    if (this.reviser !== null && this.dueDate !== null) {
      this.loading = true;
      try {
        const token = await this.activateExternalMeasures({
          dueDate: this.dueDate,
        });
        console.log(token);
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
    }
  }

  createRemoteLink() {
    this.remoteActivationLoading = true;
    this.activateExternalMeasures({
      dueDate: new Date(Date.now() + DAY_IN_MS * 14).toString(),
    })
      .then(data => {
        console.log("activateRemote responds", data);
        this.remoteActivationLoading = false;
      })
      .catch(err => {
        console.log("activateRemote failed", err);
        this.remoteActivationLoading = false;
      });
  }

  copyLink() {
    const element = (this.$refs.remoteLink as Vue).$refs
      .input as HTMLInputElement;
    console.log("copyLink", element);

    element.select();
    element.setSelectionRange(0, 99999); /* For mobile devices */

    try {
      var successful = document.execCommand("copy");
      if (successful) {
        this.linkCopyHint = this.$t(
          "components.widgets.sidebar.external_measures.link_copy_hint"
        ).toString();
        window.setTimeout(() => {
          this.linkCopyHint = null;
        }, 3000);
      }
    } catch (err) {
      alert("Oops, unable to copy");
    }

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
    }
  }
}
